import React from 'react';
import RatesProvider from "./RatesProvider";

function RootLayout(props) {
    return (


                <RatesProvider>
                    {props.children}
                </RatesProvider>



    );
}

export default RootLayout;