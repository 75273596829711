import {Cookies} from "react-cookie-consent";
import uuid from "react-uuid";
const cookieName = 'calcvals'
const expires=2;
const sameSite="strict"
const extraCookieOptions={domain:'.sussd.ie'}
export const cookieLegacyName = `${cookieName}-legacy`

export const getCookie = () => {
    let cookieValue = Cookies.get(cookieName);
    // if the cookieValue is undefined check for the legacy cookie
    if (cookieValue === undefined) {
        cookieValue = Cookies.get(cookieLegacyName);
    }
    if (cookieValue === undefined) {
        return false
    }
    return JSON.parse(cookieValue);
}
export const setCookie = (cookieValue) => {
    let id = localStorage.getItem('sussdid')
    if (!id){
        id = uuid()
        localStorage.setItem('sussdid',id)
    }
    let cookval = JSON.stringify({...cookieValue, id:id})
    let cookieSecurity = window.location ? window.location.protocol === "https:" : true;
    const cookieOptions = { expires, ...extraCookieOptions, sameSite, secure: cookieSecurity };
    // Fallback for older browsers where can not set SameSite=None,
    // SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
    if (sameSite === "none") {
        Cookies.set(cookieLegacyName, cookval, cookieOptions);
    }
    // set the regular cookie
    Cookies.set(cookieName, cookval, cookieOptions);
}